export default {
  header: {
    nav: [
      { name: "首頁", path: "/home" },
      {
        name: "主營業務",
        path: "/business",
        children: [
          {
            name: "證券業務",
            path: "/business/securities",
          },
          {
            name: "投資銀行",
            path: "/business/investBank",
          },
          {
            name: "資產管理",
            path: "/business/assetManage",
          },
          {
            name: "結構性融資",
            path: "/business/structural",
          },
        ],
      },
      { name: "軟件下載", path: "/download" },
      {
        name: "行情商城",
        path: "/mall",
        children: [
          {
            name: "港股",
            path: "/mall/activity?market=hk",
          },
          {
            name: "美股",
            path: "/mall/activity?market=us",
          },
        ],
      },
      { name: "客戶服務", path: "/service" },
      { name: "聯系我們", path: "/contact" },
      { name: "關於我們", path: "/aboutQk" },
    ],
  },
  footer: {
    address: "香港九龍柯士甸道西1號 環球貿易廣場15樓1506B室",
    onlineService: "在線客服",
    disclaimer: "免責聲明",
    privacy: "隱私策略",
    qkInfoTop: "清科國際控股有限公司 &nbsp;&nbsp;&nbsp; 版權所有，違者必究",
    recordInfo: "京公網安備 11010502047968號",
    qkInfoBottom: `Copyright &#169; ${new Date().getFullYear()} Zero2IPO International Holdings Limited. All Rights Reserved.`,
    plate1: `清科證券有限公司（Ce No：BNF723)
    <br />持有證券交易牌照（第1類）、期貨合約交易牌照（第2類）`,
    plate2: `清科資本有限公司（Ce No：BRH906)
    <br />持有就機構融資提供意見牌照（第6類）`,
    plate3: `清科資產管理有限公司（Ce No：BRP688)
    <br />持有就證券提供意見牌照（第4類）、資產管理牌照（第9類）`,
  },
  slideBar: {
    deal: "在線交易",
    open: "開戶指引",
    down: "軟件下載",
    phone: "聯系我們",
  },
  loginText: "登錄",
  exitText: "退出",
  personUserType: "個人客戶",
  mechanismUserType: "機構客戶",
  userCodeText: "客戶號",
  hello: "您好",
  login: {
    logoName: "清科證券",
    loginType: "驗證碼登錄",
    phoneLabel: "",
    phonePlaceholder: "請輸入手機號碼",
    userCodeLabel: "客戶號",
    userCodePlaceholder: "請輸入客戶號",
    phonecodeLabel: "驗證碼",
    phonecodePlaceholder: "請輸入驗證碼",
    getCode: "獲取驗證碼",
    passwordLabel: "登錄密碼",
    passwordPlaceholder: "請輸入登錄密碼",
    readLeft: "我已經閱讀並同意",
    readItem1: "《免責聲明》",
    readItem2: "《私隱聲明》",
    pwdTypeText: "密碼登錄",
    messageTypeText: "短信登錄",
  },
};
