export default {
  header: {
    nav: [
      { name: "首页", path: "/home" },
      {
        name: "主营业务",
        path: "/business",
        children: [
          {
            name: "证券业务",
            path: "/business/securities",
          },
          {
            name: "投资银行",
            path: "/business/investBank",
          },
          {
            name: "资产管理",
            path: "/business/assetManage",
          },
          {
            name: "结构性融资",
            path: "/business/structural",
          },
        ],
      },
      { name: "软件下载", path: "/download" },
      {
        name: "行情商城",
        path: "/mall",
        children: [
          {
            name: "港股",
            path: "/mall/activity?market=hk",
          },
          {
            name: "美股",
            path: "/mall/activity?market=us",
          },
        ],
      },
      { name: "客户服务", path: "/service" },
      { name: "联系我们", path: "/contact" },
      { name: "关于我们", path: "/aboutQk" },
    ],
  },
  footer: {
    address: "香港九龙柯士甸道西1号 环球贸易广场15楼1506B室",
    onlineService: "在线客服",
    disclaimer: "免责声明",
    privacy: "隐私策略",
    qkInfoTop: "清科国际控股有限公司 &nbsp;&nbsp;&nbsp; 版权所有，违者必究",
    recordInfo: "京公网安备 11010502047968号",
    qkInfoBottom: `Copyright &#169; ${new Date().getFullYear()} Zero2IPO International Holdings Limited. All Rights Reserved.`,
    plate1: `清科证券有限公司（Ce No：BNF723)
    <br />持有证券交易牌照（第1类）、期货合约交易牌照（第2类）`,
    plate2: `清科资本有限公司（Ce No：BRH906)
    <br />持有就机构融资提供意见牌照（第6类）`,
    plate3: `清科资产管理有限公司（Ce No：BRP688)
    <br />持有就证券提供意见牌照（第4类）、资产管理牌照（第9类）`,
  },
  slideBar: {
    deal: "在线交易",
    open: "开户指引",
    down: "软件下载",
    phone: "联系我们",
  },
  loginText: "登录",
  exitText: "退出",
  personUserType: "个人客户",
  mechanismUserType: "机构客户",
  userCodeText: "客户号",
  hello: "您好",
  login: {
    logoName: "清科证券",
    loginType: "验证码登录",
    phoneLabel: "",
    phonePlaceholder: "请输入手机号码",
    userCodeLabel: "客户号",
    userCodePlaceholder: "请输入客户号",
    phonecodeLabel: "验证码",
    phonecodePlaceholder: "请输入验证码",
    getCode: "获取验证码",
    passwordLabel: "登录密码",
    passwordPlaceholder: "请输入登录密码",
    readLeft: "我已经阅读并同意",
    readItem1: "《免责声明》",
    readItem2: "《私隐声明》",
    pwdTypeText: "密码登录",
    messageTypeText: "短信登录",
  },
};
