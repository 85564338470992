import service1 from "@/utils/service1";
import service from "@/utils/service";

// //行为验证初始化
export const securityGet = (data) => service.get("/security/get", data);
//行为验证码二次验证
export const securityValid = (data) => service.post("/security/valid", data);
//获取手机验证码V3
export const phonecodeGet = (data) => service.get("/v3/phonecode/get", data);
//是否显示客户号
export const userCodeDisplay = (data) =>
  service.get("/user/code/display", data);
// 验证码登陆
export const webCodeLogin = (data) => service.post("/web/code/login", data);
// 登录接口
export const webPwdLogin = (data) => service.post("/web/pwd/login", data);
//获取用户信息
export const v2UserInfo = (data) => service.get("/v2/user/info", data);
//获取用户扩展信息
export const userInfoExtend = (data) => service.get("/user/info/extend", data);
//获取用户列表
export const userTradeAccountList = (data) =>
  service1.post("/user/trade/account/list", data);
// 用户可提金额
export const userCashWithdrawable = (data) =>
  service1.post("/user/cash/withdrawable", data);
//交易密码登录
export const v1TradePwdUnlock = (data) =>
  service.post("/v1/trade/pwd/unlock", data);
