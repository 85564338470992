export default {
  router_name: "投資銀行",
  topBannerTitle: "投資銀行",
  topBannerDesc1:
    "清科資本有限公司（Zero2IPO Capital Limited）是清科國際的全資子公司，以企業融資為核心業務。清科資本已獲得香港證監會頒發的第6類受規管業務牌照（CE No:BRH906)，就機構融資提供意見。清科資本匯集了眾多來自香港和大陸兩地的資深專業人士，為境內外企業提供全面的投資銀行服務。清科資本專註於香港IPO的上市保薦、股票承銷、股權融資等服務，同時提供企業並購財務顧問、獨立財務顧問、合規顧問等業務。",
  productTitle: "業務介紹",
  productList: [
    {
      imgUrl: require("@/assets/business/investBank/product1.png"),
      title: "香港IPO保薦服務和其他企業融資服務",
      desc: "為擬於香港上市的企業提供保薦服務，亦為已在香港上市的公司提供企業並購、資產收購/出售、關連交易、再融資等項目提供財務顧問，獨立財務顧問服務。為企業在香港資本市場的發展保駕護航。",
    },
    {
      imgUrl: require("@/assets/business/investBank/product2.png"),
      title: "私募融資服務",
      desc: "為非上市公司海外私募融資提供財務顧問，解決企業上市前的資金需求。清科資本依托集團，與許多投資機構及高凈值人士保持著密切的合作關系，助企業成功股權私募融資。",
    },
  ],
  teamProductTitle: "團隊介紹",
  teamProductDesc:
    "清科資本擁有一支專業擔當、項目執行經驗豐富的精英團隊。主要團隊負責人均具有10年以上的投行實戰經驗，擁有多年的境內外業務拓展經歷，與境內外監管機構保持著良好的溝通和合作。曾成功幫助眾多企業完成IPO保薦、股票承銷、項目收購等服務，獲得良好的口碑。",
};
