export default {
  advantageList: [
    {
      imgSrc: require("@/assets/home/list1.png"),
      title: "證券業務",
      desc: "提供「一站式」的港股、美股金融服務，助客戶把握環球市場投資機遇。",
      liList: [
        {
          iconSrc: require("@/assets/home/list1-1.png"),
          text: "股票交易與結算",
        },
        {
          iconSrc: require("@/assets/home/list1-2.png"),
          text: "股票孖展融資服務",
        },
        {
          iconSrc: require("@/assets/home/list1-3.png"),
          text: "新股認購服務",
        },
        {
          iconSrc: require("@/assets/home/list1-4.png"),
          text: "證券托管及代理人服務",
        },
        {
          iconSrc: require("@/assets/home/list1-5.png"),
          text: "實時港股、美股行情信息及專業分析報告",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list2.png"),
      title: "投資銀行",
      desc: "專註於IPO上市保薦，及股權融資類財務顧問服務",
      liList: [
        {
          iconSrc: require("@/assets/home/list2-1.png"),
          text: "專業高效的保薦團隊",
        },
        {
          iconSrc: require("@/assets/home/list2-2.png"),
          text: "多元覆蓋的承銷網絡",
        },
        {
          iconSrc: require("@/assets/home/list2-3.png"),
          text: "全方位融資顧問服務",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list3.png"),
      title: "資產管理",
      desc: "全方位產品解決方案，實現資產的穩定增值",
      liList: [
        {
          iconSrc: require("@/assets/home/list3-1.png"),
          text: "依托集團強大的研究能力",
        },
        {
          iconSrc: require("@/assets/home/list3-2.png"),
          text: "全面覆蓋的投資網絡",
        },
        {
          iconSrc: require("@/assets/home/list3-3.png"),
          text: "超越市場的投資回報",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list4.png"),
      title: "結構性融資",
      desc: "量身定製完整審慎的融資方案",
      liList: [
        {
          iconSrc: require("@/assets/home/list4-1.png"),
          text: "資產抵押貸款",
        },
        {
          iconSrc: require("@/assets/home/list4-2.png"),
          text: "股權質押融資",
        },
        {
          iconSrc: require("@/assets/home/list4-3.png"),
          text: "多類結構融資項目",
        },
      ],
    },
  ],
  qkMember: "清科成員鏈接",
};
