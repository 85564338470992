export default {
  router_name: "個人資料及私隱權聲明",
  title: `<p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span style="font-family:宋体; font-size:14pt">清科證券有限公司</span></p><p style="font-size:14pt; line-height:150%; margin:0pt 0pt 0pt; orphans:0; text-align:center; widows:0"><span style="font-family:宋体; font-size:14pt">個人資料及私隱權聲明</span></p>`,
  content: `
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">清科證券有限公司，統稱「清科」或「我們」，我們致力為客戶提供優質的證券服務。</font><font
    style="vertical-align: inherit;">基於服務的需要，我們會收集和使用客戶的個人資料，我們將按照《個人資料(私隱)條例》(《香港法例》第486 章) （「私隱條例」）的規定，確保客戶的個人資料妥善保存，不被非法使用、遺失、披露及損失。</font></font></span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;"></font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">我們會從清科網站、平台、APP應用程序的訪客收集此等個人資料，乃由受訪者自願提供。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;"></font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">客戶使用清科網站、平台、APP應用程序或客戶申請或使用由清科或清科的其他成員公司提供的其他服務時，亦可收集與客戶有</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">關</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">的資料。</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;">所有資料均以開始或延續正常業務聯繫而向客戶收集</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;"></font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">為了更好的為客戶提供服務，清科網站、APP應用程序亦可能使用第三方平台提供的服務，第三方平台亦可收集客戶及客戶設備信息的部分信息，詳見附件Ⅰ。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;"></font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">本聲明事前毋須另行通知即可不時修改、修訂或更新，並為客戶與清科訂立的所有合約、協議及其他具約束力安排的主體部分。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;"></font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">閣下應定期查閱我們網站有</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">關</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">最新版本。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">一、</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;"><font style="vertical-align: inherit;">客戶須不時地向我們提供數據，包括個人身份資料、交易記錄、帳戶資金和證券持倉資料、IP地址、瀏覽器類型及版本、時區設定、瀏覽器插件類型、操作系統或平台或裝置資料等，以作私隱、或其他相</font></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;"><font style="vertical-align: inherit;">適用法律及法規下定義的敏感個人資料所述用途。</font><font
    style="vertical-align: inherit;">有</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;"><font style="vertical-align: inherit;">關</font></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">客戶的資料（及其他資料）可能用於以下用途：</font></font></span></p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">向客戶提供之服務及設施之日常運作；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">進行信貸審查及協助其他機構進行信貸審查；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">進行服務調查</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">確保客戶的信用維持良好；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">設計供客戶使用之金融服務或相</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">產品；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">向客戶推廣金融服務及相</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">關</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">產品；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">釐定拖欠客戶或客戶拖欠之債務金額；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">向客戶收取拖欠之金額，利息及手</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">續費</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">根據任何法例或規例之規定，符合作出披露之要求；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 41.75pt; orphans:0; padding-left:0.5pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">審核；及</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 41.75pt; orphans:0; padding-left:0.5pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">與任何前述部份有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">之任何用途。</font></font></span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">二、清科會對所持有的客戶資料（及其他資料）保密，但為使我們能夠向客戶提供服務、產品、信息及系統更新，我們或會向以下人士披露所有資料（及其他資料），而客戶亦必須提供相</font></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;"><font style="vertical-align: inherit;">資料，如客戶未能提供資料，清科或無法向客戶提供服務、產品、信息及系統更新。</font></font></span>
  </p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">任何向清科提供有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">其業務運作之行政、信貸</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">資</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">料、債務追討、電訊、</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">電腦</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">、繳款或其他服務之高級職員、僱員、代理、承辦商或第三者；</font></font></span>
    </li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">客戶已有或擬與之進行交易之任何金融機構；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">監管當局及其他有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">政府機構；</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">任何有責任為清科保密之其他人士，包括清科以內承諾保持此等資料機密之公司。</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">清科內公司之間對客戶之</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">資</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">料使用，須依據嚴格之內部安全標準、保密政策及適用法律。</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">我們約束僱員完全遵守該等標準、政策及法律。</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">除為了進行業務、遵守適用法律、保護免受欺詐或作出我們認為可能符合客戶利益之產品及服務優惠外，我們不會將有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">客戶的資料分發予其他公司。</font><font
      style="vertical-align: inherit;">我們亦可依據適用法律向監管當局及執法人員提供資料。</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">我們訂立了極高標準，以保護客戶的資料免受未經授權之更改或破壞。</font></font></span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">三、清科可依據個人資料（私隱）條例及其他適用法律進行以下活動：</font></font></span></p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">將客戶提供或有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">客戶之任何資料或其他</font></font></span><a
      name="_Hlk87454741"><span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">資料</font></font></span></a><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">，與清科或任何其他人士持有之資料（及其他資料）進行配對、比較或交換，以作以下用途：</font></font></span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">1)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">信貸審查；</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">2)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;">資料（及其他資料）核對；或</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">3)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">提出或核對資料（及其他資料），該等資料可能用於在任何時候採取對客戶或任何其他人士不利的行動</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">將此等資料（及其他資料）轉往香港以外任何地方（不論在香港以外處理、持有或使用此等資料（及其他資料））。</font><font
        style="vertical-align: inherit;">而有</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">關</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">客戶的資料（及其他資料）未必可以獲得與在香港法例下相同或類似程度的保障。</font></font></span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">1)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;">將受到保密要求的約束，並只會按本政策規定使用資料；及</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">2)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">需要按照法律要求。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">四、清科擬使用客戶的個人資料作直接促銷，為此我們須取得客戶的同意（包括表示不反對）。</font><font
    style="vertical-align: inherit;">就此，請客戶注意：</font></font></span></p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">清科不時持有的客戶的姓名、聯絡詳情、產品及服務投資組合信息、交易模式及行為、財務背景及統計資料可由我們用於直接促銷；</font></font></span>
    </li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">以下服務及產品的類別可作推廣：</font></font></span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">1)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">財務、保險、證券、商品、投資及相</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">關</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;">金融服務和產品及設施；</font></span></font><span style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">2)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">有</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">關</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">上文所述促銷類別的獎賞、獎勵或優惠計劃；及</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">3)</font></font></span><font style="vertical-align: inherit;"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">由清科的聯營夥伴提供有</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">關</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">上文所述促銷類別的服務和產品（有</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;">關</font></span><span
    style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;">服務和產品（視何情況而定）的申請表上會提供該等聯營夥伴的名稱）。</font></span></font><span
    style="font:7.0pt &#39;Times New Roman&#39;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">除了自行推廣上述服務、產品及促銷外，消科亦擬將以上所述資料提供予上文所述的全部或其中任何人士，以供該等人士在促銷該等服務、產品及促銷標語時使用（我們可能就此獲發或不獲發酬金)，而我們須就此用途取得閣下的書面同意（包括表示不反對）</font></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">。</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">如客戶不希望我們使用或提供客戶的資料作上述直接促銷用途，閣下可發出書面通知我們的客戶服務部，以行使閣下拒絕參與直銷活動的權利。</font></font></span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">五、查閱及修正的權利</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">根據及依據個人資料（私隱）條例之條款，每位客戶均有權：</font></font></span></p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">檢查我們是否持有有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">客戶之資料，及是否有權使用此等資料；</font></font></span>
    </li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">要求我們更改有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">客戶的不正確資料；及</font></font></span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
        style="vertical-align: inherit;">查詢我們擁有該些資料的政策和應用範圍，以及獲知我們持有有</font></font></span><span
      style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
      style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
      style="vertical-align: inherit;"><font style="vertical-align: inherit;">客戶之個人資料種類。</font></font></span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">依據個人資料（私隱）條例之條款，我們有權就處理任何資料索取查閱的要求收取合理費用。</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">六、客戶如欲查詢資料或更正資料或不同意收取直接促銷的資料或查詢有關</font></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">關</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;"><font style="vertical-align: inherit;">政策和應用範圍以及私人資料的種類等資料，請隨時致函：香港九龍柯士甸道西1號環球貿易廣場15樓1506B室合規部收。</font></font></span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">七、在清科，有</font></font></span><span style="font-family:宋体; font-size:10.5pt"><font
    style="vertical-align: inherit;"><font style="vertical-align: inherit;">關</font></font></span><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">客戶之資料只會用於進行本身業務的合法用途，以提供優質服務，及進行產品設計及優惠，從而顯示我們明白客戶及其需要。</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">八、本文不會限制閣下作為資料當事人在私隱條例下所享有的權利。</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">&nbsp;</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt; font-weight:bold"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">附件Ⅰ：</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">使用SDK名稱：友盟SDK</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">服務類型：統計分析、推送消息</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">收集個人信息類型：設備信息（IMEI/MAC/Android ID等）</font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">隱私權政策鏈接：
    <br/>
    <a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a>
    </font></font></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt"><font style="vertical-align: inherit;"><font
    style="vertical-align: inherit;">產品集成友盟+SDK及推送通道SDK（包括小米、華為），推送通道SDK需要收集採集設備標識符（IMEI/MAC/Android ID等），用於唯一標識設備，以便向用戶設備推送消息。</font><font
    style="vertical-align: inherit;">採集地理位置甄別推送通道，提高消息推送的區域覆蓋率。</font></font></span></p>

`,
};
