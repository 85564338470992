import { randomString } from "@/utils/util";

import {
  loadUserInfo,
  saveUserInfo,
  removeUserInfo,
  loadUserAccountList,
  saveUserAccountList,
  removeUserAccountList,
} from "@/utils/cache";
const state = {
  device_id: randomString(12),
  userInfo: loadUserInfo(),
  loginVisible: false,
  userAccountList: loadUserAccountList(),
};
const mutations = {
  SET_USER_INFO: (state, info) => {
    saveUserInfo(info);
    state.userInfo = info;
  },
  SET_LOGIN_VISIBLE: (state, visible) => {
    state.loginVisible = visible;
  },
  SET_USER_ACCOUNT_LIST: (state, list) => {
    const accountList = list.filter((item) => item.status === "A");
    saveUserAccountList(accountList);
    state.userAccountList = accountList;
  },
};
const getters = {};
const actions = {
  logout({ commit }) {
    const service = require("@/utils/service").default;
    const service1 = require("@/utils/service1").default;
    // console.log(service);
    service.setHeader({ user_id: "", token: "", trade_token: "" });
    service1.setHeader({ user_id: "", token: "", trade_token: "" });
    commit("SET_USER_INFO", removeUserInfo());
    commit("SET_USER_ACCOUNT_LIST", removeUserAccountList());
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
