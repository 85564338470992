export default {
  agreementArr: [
    // {
    //   name: "免责申明",
    //   path: "/about/disclaimer",
    // },
    {
      name: "客户协议书",
      path: "/about/agreement",
    },
    {
      name: "风险披露声明",
      path: "/about/risk-disclosure",
    },
    {
      name: "隐私政策",
      path: "/about/privacy",
    },
    {
      name: "网上保安",
      path: "/about/security",
    },
    {
      name: "常设授权",
      path: "/about/standing-authority",
    },
    {
      name: "税务自我证明声明",
      path: "/about/tax-statement",
    },
    // {
    //   name: "清科证券收费表",
    //   path: "/about/fee-table",
    // },

    // {
    //   name: "客户声明",
    //   path: "/about/client-statement",
    // },
  ],
};
