import help from "@/lang/moudle/service/help/hk";
import agreement from "@/lang/moudle/service/agreement/hk";
export default {
  navArr: [
    {
      name: "表格下載",
      path: "/service/table",
      imgUrl: require("@/assets/service/tableDownload.png"),
    },
    {
      name: "幫助說明",
      path: "/service/help",
      imgUrl: require("@/assets/service/help.png"),
    },
    {
      name: "協議與申明",
      path: "/service/agreement",
      imgUrl: require("@/assets/service/agreement.png"),
    },
  ],
  help,
  agreement,
};
