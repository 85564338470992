import securities from "@/lang/moudle/business/securities/cn";
import investBank from "@/lang/moudle/business/investBank/cn";
import assetManage from "@/lang/moudle/business/assetManage/cn";
import structural from "@/lang/moudle/business/structural/cn";
import transCost from "@/lang/moudle/business/transCost/cn";
export default {
  router_name: "主营业务",
  securities,
  investBank,
  assetManage,
  structural,
  transCost,
};
