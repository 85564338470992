//客户服务
const Service = () =>
  import(/* webpackChunkName:'Service' */ "@/views/service");
//表格下载
const Table = () =>
  import(/* webpackChunkName:'Table' */ "@/views/service/table");
//帮助说明
const Help = () =>
  import(/* webpackChunkName:'Table' */ "@/views/service/help");
//协议与申明
const Agreement = () =>
  import(/* webpackChunkName:'Agreement' */ "@/views/service/agreement");

const route = {
  path: "/service",
  component: Service,
  redirect: "/service/table",
  meta: {
    title: "客户服务",
  },
  children: [
    {
      name: "Table",
      path: "/service/table",
      component: Table,
      meta: {
        title: "表格下载",
      },
    },
    {
      name: "Help",
      path: "/service/help",
      component: Help,
      meta: {
        title: "帮助说明",
      },
    },
    {
      name: "Agreement",
      path: "/service/agreement",
      component: Agreement,
      meta: {
        title: "协议与申明",
      },
    },
  ],
};

export default route;
