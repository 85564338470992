import innerLayout from "@/lang/moudle/inner-layout/cn";
import common from "@/lang/moudle/common/cn";
import home from "@/lang/moudle/home/cn";
import business from "@/lang/moudle/business/cn";
import download from "@/lang/moudle/download/cn";
import contact from "@/lang/moudle/contact/cn";
import about from "@/lang/moudle/about/cn";
import aboutQk from "@/lang/moudle/aboutQk/cn";
import service from "@/lang/moudle/service/cn";
import marketMall from "@/lang/moudle/marketMall/cn";

export default {
  websiteName: "清科国际",
  innerLayout,
  common,
  home,
  business,
  download,
  contact,
  about,
  aboutQk,
  service,
  marketMall,
};
