import help from "@/lang/moudle/service/help/cn";
import agreement from "@/lang/moudle/service/agreement/cn";
export default {
  navArr: [
    {
      name: "表格下载",
      path: "/service/table",
      imgUrl: require("@/assets/service/tableDownload.png"),
    },
    {
      name: "帮助说明",
      path: "/service/help",
      imgUrl: require("@/assets/service/help.png"),
    },
    {
      name: "协议与申明",
      path: "/service/agreement",
      imgUrl: require("@/assets/service/agreement.png"),
    },
  ],
  help,
  agreement,
};
