import innerLayout from "@/lang/moudle/inner-layout/hk";
import common from "@/lang/moudle/common/hk";
import home from "@/lang/moudle/home/hk";
import business from "@/lang/moudle/business/hk";
import download from "@/lang/moudle/download/hk";
import contact from "@/lang/moudle/contact/hk";
import about from "@/lang/moudle/about/hk";
import aboutQk from "@/lang/moudle/aboutQk/hk";
import service from "@/lang/moudle/service/hk";
import marketMall from "@/lang/moudle/marketMall/hk";

export default {
  websiteName: "清科國際",
  innerLayout,
  common,
  home,
  business,
  download,
  contact,
  about,
  aboutQk,
  service,
  marketMall,
};
