export default {
  router_name: "个人资料及私隐权声明",
  title: `<p style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0"><span style="font-family:宋体; font-size:14pt">清科证券有限公司</span></p><p style="font-size:14pt; line-height:150%; margin:0pt 0pt 0pt; orphans:0; text-align:center; widows:0"><span style="font-family:宋体; font-size:14pt">个人资料及私隐权声明</span></p>`,
  content: ` <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">清科证券有限公司，统称「清科」或「我们」，我们致力为客户提供优质的证券服务。基于服务的需要，我们会收集和使用客户的个人资料，我们将按照《个人资料(私隐)条例》(《香港法例》第 486 章) （「私隐条例」）的规定，确保客户的个人资料妥善保存，不被非法使用、遗失、披露及损失。</span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt">◆</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">我们会从清科网站、平台、APP应用程序的访客收集此等个人资料，乃由受访者自愿提供。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt">◆</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">客户使用清科网站、平台、APP应用程序或客户申请或使用由清科或清科的其他成员公司提供的其他服务时，亦可收集与客户有</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">的资料。所有资料均以开始或延续正常业务联系而向客户收集</span><span
    style="font-family:宋体; font-size:10.5pt">。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt">◆</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">为了更好的为客户提供服务，清科网站、APP应用程序亦可能使用第三方平台提供的服务，第三方平台亦可收集客户及客户设备信息的部分信息，详见附件Ⅰ。</span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt">◆</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">本声明事前毋须另行通知即可不时修改、修订或更新，并为客户与清科订立的所有合约、协议及其他具约束力安排的主体部分。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 42pt; orphans:0; text-indent:-21pt; widows:0"><span
    style="font-family:Wingdings; font-size:10.5pt">◆</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">阁下应定期查阅我们网站有</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">最新版本。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">一、</span><span style="font-family:宋体; font-size:10.5pt">客户须不时地向我们提供数据，包括个人身份资料、交易记录、帐户资金和证券持仓资料、IP地址、浏览器类型及版本、时区设定、浏览器插件类型、操作系统或平台或装置资料等，以作私隐、或其他相</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">适用法律及法规下定义的敏感个人资料所述用途。有</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">客户的资料（及其他资料）可能用于以下用途：</span>
  </p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">向客户提供之服务及设施之日常运作；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">进行信贷审查及协助其他机构进行信贷审查；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">进行服务调查</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">确保客户的信用维持良好；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">设计供客户使用之金融服务或相</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">产品；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">向客户推广金融服务及相</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">产品；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">厘定拖欠客户或客户拖欠之债务金额；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">向客户收取拖欠之金额，利息及手</span><span
      style="font-family:宋体; font-size:10.5pt">续费</span><span style="font-family:宋体; font-size:10.5pt">；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">根据任何法例或规例之规定，符合作出披露之要求；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 41.75pt; orphans:0; padding-left:0.5pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">审核；及</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 41.75pt; orphans:0; padding-left:0.5pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">与任何前述部份有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">之任何用途。</span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">二、清科会对所持有的客户资料（及其他资料）保密，但为使我们能够向客户提供服务、产品、信息及系统更新，我们或会向以下人士披露所有资料（及其他资料），而客户亦必须提供相</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">资料，如客户未能提供资料，清科或无法向客户提供服务、产品、信息及系统更新。</span>
  </p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">任何向清科提供有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">其业务运作之行政、信贷</span><span
      style="font-family:宋体; font-size:10.5pt">资</span><span
      style="font-family:宋体; font-size:10.5pt">料、债务追讨、电讯、</span><span style="font-family:宋体; font-size:10.5pt">电脑</span><span
      style="font-family:宋体; font-size:10.5pt">、缴款或其他服务之高级职员、雇员、代理、承办商或第三者；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">客户已有或拟与之进行交易之任何金融机构；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">监管当局及其他有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">政府机构；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">任何有责任为清科保密之其他人士，包括清科以内承诺保持此等资料机密之公司。</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">清科内公司之间对客户之</span><span
      style="font-family:宋体; font-size:10.5pt">资</span><span style="font-family:宋体; font-size:10.5pt">料使用，须依据严格之内部安全标准、保密政策及适用法律。</span>
    </li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">我们约束雇员完全遵守该等标准、政策及法律。</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">除为了进行业务、遵守适用法律、保护免受欺诈或作出我们认为可能符合客户利益之产品及服务优惠外，我们不会将有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">客户的资料分发予其他公司。我们亦可依据适用法律向监管当局及执法人员提供资料。</span>
    </li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">我们订立了极高标准，以保护客户的资料免受未经授权之更改或破坏。</span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">三、清科可依据个人资料（私隐）条例及其他适用法律进行以下活动：</span></p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">将客户提供或有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span
      style="font-family:宋体; font-size:10.5pt">客户之任何资料或其他</span><a name="_Hlk87454741"><span
      style="font-family:宋体; font-size:10.5pt">资料</span></a><span style="font-family:宋体; font-size:10.5pt">，与清科或任何其他人士持有之资料（及其他资料）进行配对、比较或交换，以作以下用途：</span>
    </li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">1)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">信贷审查；</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">2)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">资料（及其他资料）核对；或</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">3)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">提出或核对资料（及其他资料），该等资料可能用于在任何时候采取对客户或任何其他人士不利的行动</span><span
    style="font-family:宋体; font-size:10.5pt">。</span></p>
  <ol start="2" type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">将此等资料（及其他资料）转往香港以外任何地方（不论在香港以外处理、持有或使用此等资料（及其他资料））。而有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">客户的资料（及其他资料）未必可以获得与在香港法例下相同或类似程度的保障。</span>
    </li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">1)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">将受到保密要求的约束，并只会按本政策规定使用资料；及</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">2)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">需要按照法律要求。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">四、清科拟使用客户的个人资料作直接促销，为此我们须取得客户的同意（包括表示不反对）。就此，请客户注意：</span></p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">清科不时持有的客户的姓名、联络详情、产品及服务投资组合信息、交易模式及行为、财务背景及统计资料可由我们用于直接促销；</span>
    </li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">以下服务及产品的类别可作推广：</span></li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">1)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">财务、保险、证券、商品、投资及相</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">金融服务和产品及设施；</span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">2)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">有</span><span style="font-family:宋体; font-size:10.5pt">关</span><span
    style="font-family:宋体; font-size:10.5pt">上文所述促销类别的奖赏、奖励或优惠计划；及</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 63.25pt; orphans:0; text-indent:-21.25pt; widows:0">
    <span style="font-family:宋体; font-size:10.5pt">3)</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
    style="font-family:宋体; font-size:10.5pt">由清科的联营伙伴提供有</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span
    style="font-family:宋体; font-size:10.5pt">上文所述促销类别的服务和产品（有</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">服务和产品（视何情况而定）的申请表上会提供该等联营伙伴的名称）。</span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">除了自行推广上述服务、产品及促销外，消科亦拟将以上所述资料提供予上文所述的全部或其中任何人士，以供该等人士在促销该等服务、产品及促销标语时使用（我们可能就此获发或不获发酬金)，而我们须就此用途取得阁下的书面同意（包括表示不反对）</span><span
    style="font-family:宋体; font-size:10.5pt">。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">如客户不希望我们使用或提供客户的资料作上述直接促销用途，阁下可发出书面通知我们的客户服务部，以行使阁下拒绝参与直销活动的权利。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">五、查阅及修正的权利</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">根据及依据个人资料（私隐）条例之条款，每位客户均有权：</span></p>
  <ol type="1" style="margin:0pt; padding-left:0pt">
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">检查我们是否持有有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span
      style="font-family:宋体; font-size:10.5pt">客户之资料，及是否有权使用此等资料；</span></li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">要求我们更改有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">客户的不正确资料；及</span>
    </li>
    <li
      style="font-family:宋体; font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt 36.5pt; orphans:0; padding-left:5.75pt; text-indent:0pt; widows:0">
      <span style="font-family:宋体; font-size:10.5pt">查询我们拥有该些资料的政策和应用范围，以及获知我们持有有</span><span
      style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">客户之个人资料种类。</span>
    </li>
  </ol>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:24pt; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">依据个人资料（私隐）条例之条款，我们有权就处理任何资料索取查阅的要求收取合理费用。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">六、客户如欲查询资料或更正资料或不同意收取直接促销的资料或查询有关</span><span
    style="font-family:宋体; font-size:10.5pt">关</span><span style="font-family:宋体; font-size:10.5pt">政策和应用范围以及私人资料的种类等资料，请随时致函：香港九龙柯士甸道西1号 环球贸易广场15楼1506B室 合规部收。</span>
  </p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">七、在清科，有</span><span style="font-family:宋体; font-size:10.5pt">关</span><span
    style="font-family:宋体; font-size:10.5pt">客户之资料只会用于进行本身业务的合法用途，以提供优质服务，及进行产品设计及优惠，从而显示我们明白客户及其需要。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">八、本文不会限制阁下作为资料当事人在私隐条例下所享有的权利。</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">&#xa0;</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt; font-weight:bold">附件Ⅰ：</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">使用SDK名称：友盟SDK</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">服务类型：统计分析、推送消息</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">收集个人信息类型：设备信息（IMEI/MAC/Android ID等）</span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">隐私权政策链接：<br/>
    <a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a></span></p>
  <p style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; widows:0"><span
    style="font-family:宋体; font-size:10.5pt">产品集成友盟+SDK及推送通道SDK（包括小米、华为），推送通道SDK需要收集采集设备标识符（IMEI/MAC/Android ID等），用于唯一标识设备，以便向用户设备推送消息。采集地理位置甄别推送通道，提高消息推送的区域覆盖率。</span>
  </p>`,
};
