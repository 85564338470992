export default {
  down1: {
    name: "iPhone版",
    popTitle: "iPhone掃碼下載",
  },
  down2: {
    name: "Android版",
    popTitle: "Android掃碼下載",
  },
  api: {
    name: "Open API下載",
    path: "",
  },
};
