import agreement from "@/lang/moudle/about/agreement/hk";
import riskDisclosure from "@/lang/moudle/about/riskDisclosure/hk";
import standingAuthority from "@/lang/moudle/about/standingAuthority/hk";
import taxStatement from "@/lang/moudle/about/taxStatement/hk";
import privacy from "@/lang/moudle/about/privacy/hk";
import disclaimer from "@/lang/moudle/about/disclaimer/hk";
import privacyPolicy from "@/lang/moudle/about/privacyPolicy/hk";
import security from "@/lang/moudle/about/security/hk";

export default {
  router_name: "",
  agreement,
  riskDisclosure,
  standingAuthority,
  taxStatement,
  privacy,
  disclaimer,
  privacyPolicy,
  security,
};
