export default {
  navFirst: "主營業務",
  navSecond: "證券業務",
  navThird: "交易費用",
  tableHead: ["項目名稱", "收費說明", "收費方"],
  leftNavData: [
    {
      name: "交易收費",
      id: "transCost",
      children: [
        {
          name: "香港市場",
          id: "hk",
        },
        {
          name: "美國市場",
          id: "us",
        },
      ],
    },
  ],
};
