export default {
  router_name: "證券業務",
  topBannerTitle: "證券業務",
  topBannerDesc1:
    "清科證券有限公司（Zero2IPO Securities Limited）是清科國際的全資子公司，是香港證監會認可的持牌法團（CE No：BNF723）、香港交易所參與者，持有經營第 1 類（證券交易）、第2類（期貨合約交易）受規管業務牌照。清科證券嚴謹遵循香港證監會內部監管和風險控製的要求，為客戶提供安全可靠的證券服務。",
  topBannerDesc2:
    "清科證券作為一家互聯網券商，致力於為客戶打造一個安全快捷、信息專業、功能全面的一站式金融服務商。公司用心為每一位個人、公司及機構投資者提供多元化及專業的金融服務，包括香港、美股股票承銷、證券買賣、孖展融資、新股認購和專業研究等，幫助客戶在投資道路上取得理想回報。",
  dealOnline: "在線交易",
  dealOnlineLink: "去交易",
  openOnline: "在線開戶",
  dealLinkUrl: "https://trade.qkintl.com/mts.web/Web2/login/dade/#big5",
  dealfees: "交易費用",
  dealFeesLink: "查看詳情",
  zarta: "期權激勵系統",
  btBannerTitle: "證券業務",
  btBannerDesc:
    "本公司提供所有香港交易所上市及美國交易所上市的股票，包括股本證券、交易所買賣基金（ETF）、債務證券、結構性衍生產品等的交易。",
  advantageTitle: "我們的優勢",
  advantageList: [
    {
      imgUrl: require("@/assets/business/securities/bt-1.png"),
      title: "清科國際旗下，實力保證",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-2.png"),
      title: "一站式金融服務商",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-3.png"),
      title: "安全可靠的交易技術",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-4.png"),
      title: "特低證券傭金收費",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-5.png"),
      title: "真誠、務實、高效的專業團隊",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-6.png"),
      title: "實時港股及美股行情信息及專業分析報告",
    },
  ],
};
