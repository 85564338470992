<template>
  <div id="app">
    <router-view :key="refresh" />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import api from "@/api";
export default {
  data() {
    return {};
  },
  computed: {
    refresh() {
      return this.$i18n.locale || "zh";
    },
    ...mapGetters(["userInfo"]),
  },
  // watch: {
  // ["userInfo.token"](val) {
  //   if (val) this.init();
  // },
  // },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getUserInfo();
      this.getUserAccountList();
    },
    ...mapMutations("user", ["SET_USER_ACCOUNT_LIST", "SET_USER_INFO"]),
    getUserInfo() {
      if (!this.userInfo.token) return;
      api.user.v2UserInfo().then((res) => {
        this.SET_USER_INFO({ ...this.userInfo, ...res });
      });
    },
    getUserAccountList() {
      if (!this.userInfo.token) return;
      api.user.userTradeAccountList().then((res) => {
        this.SET_USER_ACCOUNT_LIST(res);
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
