export default {
  navFirst: "主营业务",
  navSecond: "证券业务",
  navThird: "交易费用",
  tableHead: ["项目名称", "收费说明", "收费方"],
  leftNavData: [
    {
      name: "交易收费",
      id: "transCost",
      children: [
        {
          name: "香港市场",
          id: "hk",
        },
        {
          name: "美国市场",
          id: "us",
        },
      ],
    },
  ],
};
