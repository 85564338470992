import * as common from "./modules/common";
import * as service from "./modules/service";
import * as user from "./modules/user";
import * as marketMall from "./modules/marketMall";
const api = {
  common,
  service,
  user,
  marketMall,
};

export default api;
