import "babel-polyfill";
import "url-search-params-polyfill";
import Es6Promise from "es6-promise";
Es6Promise.polyfill();
// if (Number.parseInt === undefined) Number.parseInt = window.parseInt;
// if (Number.parseFloat === undefined) Number.parseFloat = window.parseFloat;
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import cssVars from "css-vars-ponyfill";

// import ErrorFn from "./utils/errorHandler";
// Vue.config.errorHandler = ErrorFn.errorHandler;
// window.onerror = ErrorFn.onerror;
// window.addEventListener("error", ErrorFn.addEventListenerError, true);
// window.addEventListener("unhandledrejection", function (e) {
//   console.log(e);
// });

cssVars({});

import VScrollLock from "v-scroll-lock";
Vue.use(VScrollLock);

import "@/components";
import "@/permission";
import "@/filters";
// 判断PC和手机
import { detect } from "./utils/util";
import { hostUrl } from "@/config";
const { os } = detect();
console.log(hostUrl);
if (os.phone) window.location.href = hostUrl;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import i18n from "@/lang";
// console.log(i18n);
import "./utils/rem";

import "animate.css";
import VueAnimateOnScroll from "vue-animate-onscroll";
import "@/style/index.scss";

Vue.use(VueAnimateOnScroll);
Vue.use(ElementUI);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
export default app;
