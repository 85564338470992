//关于
const Business = () =>
  import(/* webpackChunkName:'Business' */ "@/views/business");
//服务协议
const Securities = () =>
  import(/* webpackChunkName:'Securities' */ "@/views/business/securities");
//服务协议
const TransCost = () =>
  import(/* webpackChunkName:'TransCost' */ "@/views/business/transCost");

const InvestBank = () =>
  import(/* webpackChunkName:'InvestBank' */ "@/views/business/investBank");
const AssetManage = () =>
  import(/* webpackChunkName:'AssetManage' */ "@/views/business/assetManage");
const Structural = () =>
  import(/* webpackChunkName:'Structural' */ "@/views/business/structural");

const route = {
  path: "/business",
  component: Business,
  redirect: "/business/securities",
  meta: {
    title: "",
  },
  children: [
    {
      name: "Securities",
      path: "/business/securities",
      component: Securities,
      meta: {
        title: "business.securities.router_name",
      },
    },
    {
      name: "TransCost",
      path: "/business/transCost",
      component: TransCost,
      meta: {
        title: "business.transCost.router_name",
      },
    },
    {
      name: "InvestBank",
      path: "/business/investBank",
      component: InvestBank,
      meta: {
        title: "business.investBank.router_name",
      },
    },
    {
      name: "AssetManage",
      path: "/business/assetManage",
      component: AssetManage,
      meta: {
        title: "business.assetManage.router_name",
      },
    },
    {
      name: "Structural",
      path: "/business/structural",
      component: Structural,
      meta: {
        title: "business.structural.router_name",
      },
    },
  ],
};

export default route;
