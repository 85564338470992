import agreement from "@/lang/moudle/about/agreement/cn";
import riskDisclosure from "@/lang/moudle/about/riskDisclosure/cn";
import standingAuthority from "@/lang/moudle/about/standingAuthority/cn";
import taxStatement from "@/lang/moudle/about/taxStatement/cn";
import privacy from "@/lang/moudle/about/privacy/cn";
import disclaimer from "@/lang/moudle/about/disclaimer/cn";
import privacyPolicy from "@/lang/moudle/about/privacyPolicy/cn";
import security from "@/lang/moudle/about/security/cn";

export default {
  router_name: "",
  agreement,
  riskDisclosure,
  standingAuthority,
  taxStatement,
  privacy,
  disclaimer,
  privacyPolicy,
  security,
};
