export default {
  clickWatch: "点击查看>>",
  personTitle: "个人客户开户",
  personDesc:
    "扫码下载APP，在线填写开户信息，即刻获得开户服务。APP在线开户指引请",
  mechanismTitle: "机构客户开户",
  mechanismDesc: "机构客户开户指引请",
  clickDown: "点击下载",
  bottomDesc:
    "如需获得更为便捷的开户体验，敬请下载清科证券APP在线填写开户信息，即刻获得开户服务。",
  // iosCodeTip: "ios版",
  // dndroidCodeTip: "Android版",
  goLogin: "前往登录",
  closeWindow: "关闭窗口",
  defineText: "确定",
};
