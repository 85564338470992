import Vue from "vue";
import VueRouter from "vue-router";
const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
//隐私政策
const PrivacyPolicy = () =>
  import(
    /* webpackChunkName:'PrivacyPolicy' */ "@/layouts/inner-layout/privacy-policy"
  );

//免责声明
const Disclaimer = () =>
  import(
    /* webpackChunkName:'Disclaimer' */ "@/layouts/inner-layout/disclaimer"
  );
// 首页
const Home = () => import(/* webpackChunkName:'Home' */ "@/views/home");
// 关于
import about from "./about";
// 业务
import business from "./business";
// 客户服务
import service from "./service";

// 行情商城
import marketMall from "./marketMall";

const HelpDetail = () =>
  import(/* webpackChunkName:'HelpDetail' */ "@/views/helpDetail");
//下载
const Download = () =>
  import(/* webpackChunkName:'Download' */ "@/views/download");
//联系我们
const Contact = () =>
  import(/* webpackChunkName:'Contact' */ "@/views/contact");
//关于清科
const AboutQk = () =>
  import(/* webpackChunkName:'AboutQk' */ "@/views/aboutQk");
//404
const notFound = () =>
  import(/* webpackChunkName:'notFound' */ "@/views/notFound");

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "InnerLayout",
    component: InnerLayout,
    meta: {},
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
          title: "首页",
        },
      },
      {
        name: "PrivacyPolicy",
        path: "/privacy-policy",
        component: PrivacyPolicy,
        meta: {
          title: "隐私策略",
        },
      },

      {
        name: "Disclaimer",
        path: "/disclaimer",
        component: Disclaimer,
        meta: {
          title: "免责声明",
        },
      },
      about,
      business,
      service,
      marketMall,
      {
        path: "/download",
        name: "Download",
        component: Download,
        meta: {
          title: "软件下载",
        },
      },
      {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
          title: "联系我们",
        },
      },
      {
        path: "/aboutQk",
        name: "AboutQk",
        component: AboutQk,
        meta: {
          title: "关于清科",
        },
      },
      {
        path: "/helpDetail",
        name: "HelpDetail",
        component: HelpDetail,
        meta: {
          title: "帮助详情",
        },
      },
    ],
  },

  {
    path: "/404",
    name: "notFound",
    component: notFound,
  },
  {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior(to, from, savedPosition) {
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    } // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 1 });
      }, 0);
    });
  },
});

export default router;
