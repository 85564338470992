import router from "./router";
import getPageTitle from "./utils/get-page-title";
import store from "@/store";
import lang from "@/lang";
router.beforeEach(async (to, from, next) => {
  const { title, needLogin = false } = to.meta;
  getPageTitle(lang.t(title));
  const { token } = store.getters.userInfo;
  if (to.path === "/404") next("/home");
  else {
    if (needLogin && !token) next("/home");
    else next();
  }
});
