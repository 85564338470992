// 用于需要在get请求中传递数组的情况
export function paramsSerializer(params = {}) {
  const paramArr = [];
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((item) =>
        paramArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
      );
    } else {
      paramArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  return paramArr.join("&");
}

/**
 * 获取url中的查询字符串参数
 * @param {String} url  url字符串
 */
export function getURLParams(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}

//form表单格式转换
export function stringifyParms(params) {
  return Object.keys(params)
    .sort()
    .map((key) => key + "=" + params[key])
    .join("&");
}
