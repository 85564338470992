// 正式
// const baseUrl = "https://qkzq.qkintl.com";
// const hostUrl = "https://m.qkintl.com/";
// const { protocol, host, pathname } = window.location;
// const hostUrl = `${protocol}//m.${host.replace("www.", "")}${pathname}`;

const baseUrl = "https://app.qksec.hk";

const { protocol, host, pathname, hash } = window.location;
const hostUrl = `${protocol}//m.${host.replace("www.", "")}${pathname}${hash}`;
// if (hash.includes("#/helpDetail")) {
//   const params = hash.split("#/helpDetail?")[1];
//   if (params) {
//     const article_id = params.split("article_id=")[1];
//     hostUrl = `$${protocol}//m.${host.replace(
//       "www.",
//       ""
//     )}${pathname}#/Customer/helpdetail?code=${article_id}`;
//   }
// }
console.log(hostUrl);

module.exports = {
  title: "",
  hostUrl,
  api: {
    base_api: `${baseUrl}/api`,
    base_api2: `${baseUrl}/api2`,
  },
};
