export default {
  componeyName: "清科国际",
  componeyDesc1:
    "清科国际控股（香港）有限公司是清科创业（股票代码：1945.HK）的全资子公司，是一家立足香港，面向全球的投资银行，致力于成为连接中国与海外资本市场的桥梁，助力中国企业走向国际资本市场。清科国际依托清科在股权投资行业20年的经验及资源积累，积极开展跨境及境外企业融资、证券交易、资产管理等业务。",
  componeyDesc2:
    "清科国际拥有一支专业水平出色、执行经验丰富的团队。主要团队负责人均具有10年以上的投行实战经验和境内外业务拓展经历，与境内外监管机构保持着良好的沟通和合作。团队曾成功帮助众多企业完成IPO、股票承销、项目并购等，获得良好的口碑。",
  componeyDesc3:
    "此外，清科国际依托大数据及互联网技术，创新地将市场和金融资源整合，为前往香港和海外资本市场的客户提供一站式综合服务。清科国际借助自有的行业投融资大数据帮助企业发现价值、确认价值、提升价值、优化资源配置，精准对接资本方。",
  advantageName: "核心优势",
  advantageDesc:
    "清科国际依托大数据及互联网技术将市场金融资源整合，为前往香港和海外市场的客户提供股票交易、投资银行服务及资产管理等业务。清科国际能够给客户提供市场上最全面的投融资大数据并帮助其对接资本方，帮助他们发现企业价值，优化资本、配置资源。",
  advantageListTitle1: "行业数据库衍生的投行",
  advantageListDesc1:
    "清科深耕中国股权投资行业20年，积累了行业的数据库。清科国际依托该数据库和相关经验，为创业者、拟上市公司及已上市企业提供全方位融资服务，为投资方获取优质企业资源。",
  advantageListTitle2: "超群的研究实力",
  advantageListDesc2:
    "清科研究中心在国内市场影响力持续提升，清科研究中心的股权行业报告内容覆盖五大系列，早期投资、创业投资、私募股权投资、企业并购、企业上市。",
  advantageListTitle3: "全面的投资人覆盖网络",
  advantageListDesc3:
    "投资人网络覆盖超过7万家，借助20年在创投圈举办的各类排名及投资论坛活动等，积累了具市场影响力的投资人网络资源",
  advantageListTitle4: "便捷的金融大数据搜索系统",
  advantageListDesc4:
    "开发了包含“投资人大数据A-Z一站式索引”，“在线路演，销售，云业绩发布通道服务”，依托母公司PEDATA MAX强大的投资信息数据系统，满足用户“省时高效”、“资讯及时全面”的核心诉求。",
};
