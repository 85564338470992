// 注册全局组件，这样使用的时候不用每次都import。
import Vue from "vue";
import SvgIcon from "./base/svg-icon";
Vue.component("svg-icon", SvgIcon);
//导入所有svg图标，然后结合svg-sprite-loader可以按需使用
const requireContext = require.context(
  "./base/svg-icon/icons",
  false,
  /\.svg$/
);
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
requireAll(requireContext);
