export default {
  helpArr: [
    {
      title: "开户相关",
      iconUrl: require("@/assets/service/1.png"),
      list: [
        { name: "机构如何开户?", path: "" },
        { name: "个人如何开户?", path: "" },
        { name: "如何更改开户资料?", path: "" },
      ],
    },
    {
      title: "资金相关",
      iconUrl: require("@/assets/service/2.png"),
      list: [
        { name: "入金指引", path: "" },
        { name: "出金指引", path: "" },
        { name: "管理银行卡", path: "" },
      ],
    },
    {
      title: "股票转仓",
      iconUrl: require("@/assets/service/3.png"),
      list: [
        { name: "转入股票", path: "" },
        { name: "转出股票", path: "" },
      ],
    },
    {
      title: "港股IPO认购",
      iconUrl: require("@/assets/service/4.png"),
      list: [
        { name: "IPO认购说明", path: "" },
        { name: "IPO认购流程", path: "" },
        { name: "IPO中签查询", path: "" },
      ],
    },
    {
      title: "港股交易规则",
      iconUrl: require("@/assets/service/5.png"),
      list: [
        { name: "港股交易时间", path: "" },
        { name: "港股交易和收费规则", path: "" },
        { name: "港股涨跌幅说明", path: "" },
        { name: "港股订单类型", path: "" },
        { name: "港股订单期限说明", path: "" },
      ],
    },
    {
      title: "港股行情",
      iconUrl: require("@/assets/service/6.png"),
      list: [{ name: "港股行情使用说明", path: "" }],
    },
  ],
};

// export default {
//   helpArr: [
//     {
//       group: [
//         {
//           title: "开户相关",
//           iconUrl: require("@/assets/service/1.png"),
//           list: [
//             { name: "机构如何开户?", path: "" },
//             { name: "个人如何开户?", path: "" },
//             { name: "如何更改开户资料?", path: "" },
//           ],
//         },
//         {
//           title: "资金相关",
//           iconUrl: require("@/assets/service/2.png"),
//           list: [
//             { name: "入金指引", path: "" },
//             { name: "出金指引", path: "" },
//             { name: "管理银行卡", path: "" },
//           ],
//         },
//         {
//           title: "股票转仓",
//           iconUrl: require("@/assets/service/3.png"),
//           list: [
//             { name: "转入股票", path: "" },
//             { name: "转出股票", path: "" },
//           ],
//         },
//       ],
//     },
//     {
//       group: [
//         {
//           title: "港股IPO认购",
//           iconUrl: require("@/assets/service/4.png"),
//           list: [
//             { name: "IPO认购说明", path: "" },
//             { name: "IPO认购流程", path: "" },
//             { name: "IPO中签查询", path: "" },
//           ],
//         },
//         {
//           title: "港股交易规则",
//           iconUrl: require("@/assets/service/5.png"),
//           list: [
//             { name: "港股交易时间", path: "" },
//             { name: "港股交易和收费规则", path: "" },
//             { name: "港股涨跌幅说明", path: "" },
//             { name: "港股订单类型", path: "" },
//             { name: "港股订单期限说明", path: "" },
//           ],
//         },
//         {
//           title: "港股行情",
//           iconUrl: require("@/assets/service/6.png"),
//           list: [{ name: "港股行情使用说明", path: "" }],
//         },
//       ],
//     },
//   ],
// };
