export default {
  banner: require("@/assets/v2/marketMall/us_banner.png"),
  use_table: {
    head: ["行情功能", "延时行情", "LV1实时行情"],
    body: [
      {
        td: [
          {
            name: "实时报价",
            imgSrc: "",
          },
          {
            name: "延时15分钟",
            imgSrc: "",
          },
          {
            name: "实时刷新",
            imgSrc: "",
          },
        ],
      },
      {
        td: [
          {
            name: "买卖盘口",
            imgSrc: "",
          },
          {
            name: "无",
            imgSrc: require("@/assets/marketMall/no_icon.png"),
            iconName: "error_fill",
          },
          {
            name: " 1档",
            imgSrc: require("@/assets/marketMall/has_icon.png"),
            iconName: "right_fill",
          },
        ],
      },
      {
        td: [
          {
            name: "逐笔成交",
            imgSrc: "",
          },
          {
            name: "无",
            imgSrc: require("@/assets/marketMall/no_icon.png"),
            iconName: "error_fill",
          },
          {
            name: "支持",
            imgSrc: require("@/assets/marketMall/has_icon.png"),
            iconName: "right_fill",
          },
        ],
      },
    ],
  },
  use_img_list: [
    {
      top_img: require("@/assets/v2/marketMall/1@3x.png"),
      bottom_img: require("@/assets/v2/marketMall/us_demonstration1@3x.png"),
    },
    {
      top_img: require("@/assets/v2/marketMall/2@3x.png"),
      bottom_img: require("@/assets/v2/marketMall/us_demonstration2@3x.png"),
    },
    {
      top_img: require("@/assets/v2/marketMall/3@3x.png"),
      bottom_img: require("@/assets/v2/marketMall/us_demonstration3@3x.png"),
    },
  ],
  huikui_title: require("@/assets/v2/marketMall/us_5@3x.png"),
  huikui_desc:
    "当月账户日均总资产≥50万(等值港元)并且购买过美股实时行情卡，月末可获得以下一种回馈",
  huikui_desc_tip:
    "(如现金账户和美股沽空账户均已开通，回馈金额将返还到现金账户)",
  huikui_list_item: [
    {
      title: "回馈一",
      phone_title: "手机版美股行情卡",
      pc_title: "PC版美股行情卡",
      desc: "125USD<当月美股交易实付佣金<250USD，可返还手机版或PC版美股行情卡的购买费用",
      tip: "(返还您购买的版本费用。如果购买了多个版本，返还价格最高的费用)",
      or: "或",
    },
    {
      title: "回馈二",
      phone_title: "手机版美股行情卡",
      pc_title: "PC版美股行情卡",
      desc: "当月美股交易实付佣金≥250USD，最多可返还手机版+PC版美股行情卡的购买费用，以实际购买行情卡的情况为准",
      tip: "",
      or: "和",
    },
  ],
  questionButton: "常见问题",
  question_list: [
    {
      q: "Q1：我没有购买美股实时行情服务，但我在当月日均总资产≥50万(等值港元)并且当月美股实付佣金>250美元，能不能获得行情回馈？",
      a: "A1：不能，只有购买美股实时行情服务后，并满足上述条件才可以获得回馈。",
    },
    {
      q: "Q2：我在当月内日均总资产只有49万(等值港元)，但当月美股实付佣金>250美元，能不能获得行情回馈？",
      a: "A2：不能，您需要同时满足日均总资产及美股实付佣金两个条件。",
    },
    {
      q: "Q3：哪些美股交易会计入实付佣金？",
      a: "A3：美股行情回馈计划仅限于美股市场，仅计算美股交易佣金，包含正股、ETF和衍生品的交易，其他交易暂不计入。",
    },
    {
      q: "Q4：我几乎一整个月的总资产都在50万(等值港元)以上，但是最后几天因为市场行情波动导致总资产跌至50万(等值港元)以下，还能享有行情回馈么？",
      a: "A4：平台按照日均总资产≥50万(等值港元)进行统计。如满足这个条件，即使当月某几天总资产＜50万(等值港元)，也算满足资产条件。",
    },
    {
      q: "Q5：我同时购买了手机版和PC版美股实时行情服务，我在当月内日均资产≥50万(等值港元)，当月美股实付佣金大于125美元但不到250美元，我会获得手机版还是PC版的费用回馈？",
      a: "A5：你将会获得PC版费用回馈。",
    },
  ],
  question_title: "购买美股行情常见问题",
  answer_footer:
    "以上美股实时报价服务使用规则最终解释权归清科证券有限公司所有，如有疑问请联系客服。",
  open_status_text:
    "您尚未开户，无法购买美股实时行情。如需开户，可到清科官网的“客户服务->帮助说明”页面查看开户相关指引",
  open_status_text2:
    "您尚未设置交易密码，无法购买美股实时行情。请在清科证券APP“我的->账户与安全”中完成设置",
  open_status_text3: "您的证券账户已禁用，无法购买美股实时行情。",
  piStatus_title: "行情用户身份声明",
  piStatus_content: `
  因美国交易所规定，使用美股实时行情服务前需要确认用户的身份。清科证券将向非专业用户和专业用户提供不同类型的美股行情服务。<br /><br />
  如果您不满足下述标准中的任何一项，则您属于「非专业用户」<br /><br />
  如果您满足下述标准中的一项或者多项，则您属于「专业用户」：<br /><br />
  1、如果您代表公司、企业、合作方、信托或协会进行订阅。<br /><br />
  2、您将这些资讯用于任何交易或商务活动而非个人投资。<br /><br />
  3、您计划向他人提供这些资讯。<br /><br />
  4、您是一名券商交易员、注册代表、投资顾问、投资银行家、期货经纪商、商品引入经纪人或商品交易顾问、资本经营者、交易所或期货合约市场的成员或任何上述方的所有人、合伙人或相关人员。<br /><br />
  5、您是银行或保险公司的雇员，或者您任何从事证券或期货投资或交易相关业务的附属机构的雇员。<br /><br />
  6、专业用户购买美股实时行情的费用高于非专业用户。<br /><br />
  <span style="color:var(--explainColor)">请注意：用户对申报专业和非专业身份负有全部责任，若未正确申报身份，需承担所有适用的罚款以及/或交易费用。</span>
  `,
  piStatus_lButton: "我是专业用户",
  piStatus_RButton: "我是非专业用户",
  buy_now: "立即购买",
  confirmText: "确定",
  submmitText: "提交",
  cancelText: "取消",
  repeatInput: "重新输入",
  buySuccess: "购买成功",
  buyPage: {
    p_title1: "请选择行情卡类型",
    month: "月",
    renewRadioText: "连续包月",
    cancel_month: "取消连续包月",
    p_title2: "请选择支付账户",
    buyTipTitle: "购买说明",
    buyTipContent: `1.美股实时行情分为手机版和PC版。手机版仅限手机设备使用，PC版仅限PC设备使用。<br />

    2.购买美股实时行情，会从支付的证券账户内的美元余额中扣除行情卡费用，若美元余额不足会购买失败。<br />
    
    3.手机版购买成功后立即生效，PC版购买后需T+1日生效。美股实时行情使用期按自然月(美东时间)计算，从购买成功之日起，至该月最后一天止，不足1个月按1个月计费。<br />
    
    4.连续包月生效期间，系统会在每月最后一天晚间从支付的证券账户内的美元余额中扣除下个月的行情卡费用，美元余额不足会导致续订终止。<br />
    
    5.美股实时行情仅可在一个设备上使用，仅在新(最后)登入的设备上生效。<br />
    
    6.同版本的美股实时行情失效后才能再次购买。<br />
    
    7.美股实时行情购买成功后，不能退订，请您慎重考虑后再行付款购买。<br />
    
    8.如有任何争议请咨询客服，清科证券有限公司对于上述的条款和条件有最终解释权。`,
    userAccTip: `美元余额不足(或有负债)，请先到清科证券App<span>兑换</span>或<span>存入美元</span>`,
    userAccTipC: "证券账户已注销，无法购买美股实时行情月卡",
    readed: `我已阅读并同意`,
    readedHref: `《行情使用-免责声明》`,
    buy_bottom_left: "支付金额",
    orderTitle: "订单确认",
    full_name: "名称",
    ccyPrice: "金额",
    nameCode: "支付账户",
    end_date: "到期日期",

    open_continue_title: "开通连续包月",
    ftip: "本行情卡有效期已不足1个月，但会收取1个月的费用<br />",
    ftip1:
      "同时将为您开通连续包月服务。每个月的最后一天将会从您选定支付的证券账户中，扣除下个月的行情服务费用，请确保支付的证券账户美元余额充足",
    cancel_tip: `取消包月后，从下月起将无法查看美股实时行情。是否确定取消连续包月？`,
    cancel_tip2: `取消包月成功,请到清科证券APP查看续订状态`,
    tradePasswordError: `交易密码错误，请重新输入密码，如忘记密码，请在清科证券APP“我的->账户安全”中完成密码找回`,
  },
};
