export default {
  down1: {
    name: "iPhone版",
    popTitle: "iPhone扫码下载",
  },
  down2: {
    name: "Android版",
    popTitle: "Android扫码下载",
  },
  api: {
    name: "Open API下载",
    path: "",
  },
};
