import Vue from "vue";

// import formatDate from "./formatDate";
import { isRealNumber } from "@/utils/util";

export function formatDate(time, fmt) {
  if (!time || time === 0) {
    return "---";
  }
  if (!isRealNumber(time) && time.replace)
    time = time.replace(new RegExp(/-/gm), "/");
  else if (isRealNumber(time)) time = Number(time);
  const date = new Date(time);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  const o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      const str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}
const filters = {
  formatDate,
};

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
