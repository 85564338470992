import service1 from "@/utils/service1";
import service from "@/utils/service";

//获取产品行情列表
export const quoteProductList = (data) =>
  service.get("/quote/product/list", data);
//行情卡购买前校验V2
export const quoteCardCheckV2 = (data) =>
  service.get("/quote/card/check/v2", data);
// 行情卡购买V2
export const quotePayV2 = (data) => service.post("/quote/pay/v2", data);
//取消连续包月
export const quoteCancelRenewalV2 = (data) =>
  service.post("/quote/cancel/renewal/v2", data);

//获取产品行情列表
export const quoteProductListV3 = (data) =>
  service.get("/v3/quote/product/list", data);
//行情卡购买前校验V3
export const quoteCardCheckV3 = (data) =>
  service.get("/v3/quote/card/check", data);
// 行情卡购买V3
export const quotePayV3 = (data) => service.post("/v3/quote/pay", data);
//取消连续包月v3
export const quoteCancelRenewalV3 = (data) =>
  service.post("/v3/quote/cancel/renewal", data);
