const UserInfoKey = "User-Info"; // 用户信息 {} {...}
const userAccountListKey = "User-Account-List";

// 获取用户信息
export function loadUserInfo() {
  return JSON.parse(localStorage.getItem(UserInfoKey)) || {};
}
// 保存用户信息
export function saveUserInfo(userInfo) {
  localStorage.setItem(UserInfoKey, JSON.stringify(userInfo));
  return userInfo;
}
// 删除用户信息
export function removeUserInfo() {
  localStorage.removeItem(UserInfoKey);
  return {};
}

// 获取用户信息
export function loadUserAccountList() {
  return JSON.parse(localStorage.getItem(userAccountListKey)) || {};
}
// 保存用户信息
export function saveUserAccountList(userInfo) {
  localStorage.setItem(userAccountListKey, JSON.stringify(userInfo));
  return userInfo;
}
// 删除用户信息
export function removeUserAccountList() {
  localStorage.removeItem(userAccountListKey);
  return [];
}
