export default {
  banner: require("@/assets/v2/marketMall/us_banner.png"),
  use_table: {
    head: ["行情功能", "延時行情", "LV1實時行情"],
    body: [
      {
        td: [
          {
            name: "實時報價",
            imgSrc: "",
          },
          {
            name: "延時15分鐘",
            imgSrc: "",
          },
          {
            name: "實時刷新",
            imgSrc: "",
          },
        ],
      },
      {
        td: [
          {
            name: "買賣盤口",
            imgSrc: "",
          },
          {
            name: "無",
            imgSrc: require("@/assets/marketMall/no_icon.png"),
            iconName: "error_fill",
          },
          {
            name: " 1檔",
            imgSrc: require("@/assets/marketMall/has_icon.png"),
            iconName: "right_fill",
          },
        ],
      },
      {
        td: [
          {
            name: "逐筆成交",
            imgSrc: "",
          },
          {
            name: "無",
            imgSrc: require("@/assets/marketMall/no_icon.png"),
            iconName: "error_fill",
          },
          {
            name: "支持",
            imgSrc: require("@/assets/marketMall/has_icon.png"),
            iconName: "right_fill",
          },
        ],
      },
    ],
  },
  use_img_list: [
    {
      top_img: require("@/assets/v2/marketMall/1@3x.png"),
      bottom_img: require("@/assets/v2/marketMall/us_demonstration1@3x.png"),
    },
    {
      top_img: require("@/assets/v2/marketMall/2@3x.png"),
      bottom_img: require("@/assets/v2/marketMall/us_demonstration2@3x.png"),
    },
    {
      top_img: require("@/assets/v2/marketMall/3@3x.png"),
      bottom_img: require("@/assets/v2/marketMall/us_demonstration3@3x.png"),
    },
  ],
  huikui_title: require("@/assets/v2/marketMall/us_5@3x.png"),
  huikui_desc:
    "當月賬戶日均總資產≥50萬(等值港元)並且購買過美股實時行情卡，月末可獲得以下一種回饋",
  huikui_desc_tip:
    "(如現金賬戶和美股沽空賬戶均已開通，回饋金額將返還到現金賬戶)",
  huikui_list_item: [
    {
      title: "回饋一",
      phone_title: "手機版美股行情卡",
      pc_title: "PC版美股行情卡",
      desc: "125USD<當月美股交易實付傭金<250USD，可返還手機版或PC版美股行情卡的購買費用",
      tip: "(返還您購買的版本費用。如果購買了多個版本，返還價格最高的費用)",
      or: "或",
    },
    {
      title: "回饋二",
      phone_title: "手機版美股行情卡",
      pc_title: "PC版美股行情卡",
      desc: "當月美股交易實付傭金≥250USD，最多可返還手機版+PC版美股行情卡的購買費用，以實際購買行情卡的情況為準",
      tip: "",
      or: "和",
    },
  ],
  questionButton: "常見問題",
  question_list: [
    {
      q: "Q1：我沒有購買美股實時行情服務，但我在當月日均總資產≥50萬(等值港元)並且當月美股實付傭金>250美元，能不能獲得行情回饋？",
      a: "A1：不能，只有購買美股實時行情服務後，並滿足上述條件才可以獲得回饋。",
    },
    {
      q: "Q2：我在當月內日均總資產只有49萬(等值港元)，但當月美股實付傭金>250美元，能不能獲得行情回饋？",
      a: "A2：不能，您需要同時滿足日均總資產及美股實付傭金兩個條件。",
    },
    {
      q: "Q3：哪些美股交易會計入實付傭金？",
      a: "A3：美股行情回饋計劃僅限於美股市場，僅計算美股交易傭金，包含正股、ETF和衍生品的交易，其他交易暫不計入。",
    },
    {
      q: "Q4：我幾乎一整個月的總資產都在50萬(等值港元)以上，但是最後幾天因為市場行情波動導致總資產跌至50萬(等值港元)以下，還能享有行情回饋麽？",
      a: "A4：平臺按照日均總資產≥50萬(等值港元)進行統計。如滿足這個條件，即使當月某幾天總資產＜50萬(等值港元)，也算滿足資產條件。",
    },
    {
      q: "Q5：我同時購買了手機版和PC版美股實時行情服務，我在當月內日均資產≥50萬(等值港元)，當月美股實付傭金大於125美元但不到250美元，我會獲得手機版還是PC版的費用回饋？",
      a: "A5：你將會獲得PC版費用回饋。",
    },
  ],
  question_title: "購買美股行情常見問題",
  answer_footer:
    "以上美股實時報價服務使用規則最終解釋權歸清科證券有限公司所有，如有疑問請聯系客服。",
  open_status_text:
    "您尚未開戶，無法購買美股實時行情。如需開戶，可到清科官網的「客戶服務->幫助說明」頁面查看開戶相關指引",
  open_status_text2:
    "您尚未設置交易密碼，無法購買美股實時行情。請在清科證券APP「我的->賬戶與安全」中完成設置",
  open_status_text3: "您的證券賬戶已禁用，無法購買美股實時行情。",
  piStatus_title: "行情用戶身份聲明",
  piStatus_content: `
  因美國交易所規定，使用美股實時行情服務前需要確認用戶的身份。清科證券將向非專業用戶和專業用戶提供不同類型的美股行情服務。<br /><br />
  如果您不滿足下述標準中的任何一項，則您屬於「非專業用戶」<br /><br />
  如果您滿足下述標準中的一項或者多項，則您屬於「專業用戶」：<br /><br />
  1、如果您代表公司、企業、合作方、信托或協會進行訂閱。<br /><br />
  2、您將這些資訊用於任何交易或商務活動而非個人投資。<br /><br />
  3、您計劃向他人提供這些資訊。<br /><br />
  4、您是一名券商交易員、註冊代表、投資顧問、投資銀行家、期貨經紀商、商品引入經紀人或商品交易顧問、資本經營者、交易所或期貨合約市場的成員或任何上述方的所有人、合夥人或相關人員。<br /><br />
  5、您是銀行或保險公司的雇員，或者您任何從事證券或期貨投資或交易相關業務的附屬機構的雇員。<br /><br />
  6、專業用戶購買美股實時行情的費用高於非專業用戶。<br /><br />
  <span style="color:var(--explainColor)">請註意：用戶對申報專業和非專業身份負有全部責任，若未正確申報身份，需承擔所有適用的罰款以及/或交易費用。</span>
  `,
  piStatus_lButton: "我是專業用戶",
  piStatus_RButton: "我是非專業用戶",
  buy_now: "立即購買",
  confirmText: "確定",
  submmitText: "提交",
  cancelText: "取消",
  repeatInput: "重新輸入",
  buySuccess: "購買成功",
  buyPage: {
    p_title1: "請選擇行情卡類型",
    month: "月",
    renewRadioText: "連續包月",
    cancel_month: "取消連續包月",
    p_title2: "請選擇支付賬戶",
    buyTipTitle: "購買說明",
    buyTipContent: `1.美股實時行情分為手機版和PC版。手機版僅限手機設備使用，PC版僅限PC設備使用。<br />

    2.購買美股實時行情，會從支付的證券賬戶內的美元余額中扣除行情卡費用，若美元余額不足會購買失敗。<br />
    
    3.手機版購買成功後立即生效，PC版購買後需T+1日生效。美股實時行情使用期按自然月(美東時間)計算，從購買成功之日起，至該月最後一天止，不足1個月按1個月計費。<br />
    
    4.連續包月生效期間，系統會在每月最後一天晚間從支付的證券賬戶內的美元余額中扣除下個月的行情卡費用，美元余額不足會導致續訂終止。<br />
    
    5.美股實時行情僅可在一個設備上使用，僅在新(最後)登入的設備上生效。<br />
    
    6.同版本的美股實時行情失效後才能再次購買。<br />
    
    7.美股實時行情購買成功後，不能退訂，請您慎重考慮後再行付款購買。<br />
    
    8.如有任何爭議請咨詢客服，清科證券有限公司對於上述的條款和條件有最終解釋權。`,
    userAccTip: `美元余額不足(或有負債)，請先到清科證券App<span style="color:var(--blue)">兌換</span>或<span style="color:var(--blue)">存入美元</span>`,
    userAccTipC: "證券賬戶已註銷，無法購買美股實時行情月卡",
    readed: `我已閱讀並同意`,
    readedHref: `《行情使用-免責聲明》`,
    buy_bottom_left: "支付金額",
    orderTitle: "訂單確認",
    full_name: "名稱",
    ccyPrice: "金額",
    nameCode: "支付賬戶",
    end_date: "到期日期",
    open_continue_title: "開通連續包月",
    ftip: "本行情卡有效期已不足1個月，但會收取1個月的費用<br />",
    ftip1:
      "同時將為您開通連續包月服務。每個月的最後一天將會從您選定支付的證券賬戶中，扣除下個月的行情服務費用，請確保支付的證券賬戶美元余額充足",
    cancel_tip: `取消包月後，從下月起將無法查看美股實時行情。是否確定取消連續包月？`,
    cancel_tip2: `取消包月成功,請到清科證券APP查看續訂狀態`,
    tradePasswordError: `交易密碼錯誤，請重新輸入密碼，如忘記密碼，請在清科證券APP「我的->賬戶安全」中完成密碼找回`,
  },
};
