export default {
  router_name: "证券业务",
  topBannerTitle: "证券业务",
  topBannerDesc1:
    "清科证券有限公司（Zero2IPO Securities Limited）是清科国际的全资子公司，是香港证监会认可的持牌法团（CE No：BNF723）、香港交易所参与者，持有经营第 1 类（证券交易）、第2类（期货合约交易）受规管业务牌照。清科证券严谨遵循香港证监会内部监管和风险控制的要求，为客户提供安全可靠的证券服务。",
  topBannerDesc2:
    "清科证券作为一家互联网券商，致力于为客户打造一个安全快捷、信息专业、功能全面的一站式金融服务商。公司用心为每一位个人、公司及机构投资者提供多元化及专业的金融服务，包括香港、美股股票承销、证券买卖、孖展融资、新股认购和专业研究等，帮助客户在投资道路上取得理想回报。",
  dealOnline: "在线交易",
  dealOnlineLink: "去交易",
  openOnline: "在线开户",
  dealLinkUrl: "https://trade.qkintl.com/mts.web/Web2/login/dade/#gb",
  dealfees: "交易费用",
  dealFeesLink: "查看详情",
  zarta: "期权激励系统",
  btBannerTitle: "证券业务",
  btBannerDesc:
    "本公司提供所有香港交易所上市及美国交易所上市的股票，包括股本证券、交易所买卖基金（ETF）、债务证券、结构性衍生产品等的交易。",
  advantageTitle: "我们的优势",
  advantageList: [
    {
      imgUrl: require("@/assets/business/securities/bt-1.png"),
      title: "清科国际旗下，实力保证",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-2.png"),
      title: "一站式金融服务商",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-3.png"),
      title: "安全可靠的交易技术",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-4.png"),
      title: "特低证券佣金收费",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-5.png"),
      title: "真诚、务实、高效的专业团队",
    },
    {
      imgUrl: require("@/assets/business/securities/bt-6.png"),
      title: "实时港股及美股行情信息及专业分析报告",
    },
  ],
};
