export default {
  advantageList: [
    {
      imgSrc: require("@/assets/home/list1.png"),
      title: "证券业务",
      desc: "提供“一站式”的港股、美股金融服务，助客户把握环球市场投资机遇。",
      liList: [
        {
          iconSrc: require("@/assets/home/list1-1.png"),
          text: "股票交易与结算",
        },
        {
          iconSrc: require("@/assets/home/list1-2.png"),
          text: "股票孖展融资服务",
        },
        {
          iconSrc: require("@/assets/home/list1-3.png"),
          text: "新股认购服务",
        },
        {
          iconSrc: require("@/assets/home/list1-4.png"),
          text: "证券托管及代理人服务",
        },
        {
          iconSrc: require("@/assets/home/list1-5.png"),
          text: "实时港股、美股行情信息及专业分析报告",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list2.png"),
      title: "投资银行",
      desc: "专注于IPO上市保荐，及股权融资类财务顾问服务",
      liList: [
        {
          iconSrc: require("@/assets/home/list2-1.png"),
          text: "专业高效的保荐团队",
        },
        {
          iconSrc: require("@/assets/home/list2-2.png"),
          text: "多元覆盖的承销网络",
        },
        {
          iconSrc: require("@/assets/home/list2-3.png"),
          text: "全方位融资顾问服务",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list3.png"),
      title: "资产管理",
      desc: "全方位产品解决方案，实现资产的稳定增值",
      liList: [
        {
          iconSrc: require("@/assets/home/list3-1.png"),
          text: "依托集团强大的研究能力",
        },
        {
          iconSrc: require("@/assets/home/list3-2.png"),
          text: "全面覆盖的投资网络",
        },
        {
          iconSrc: require("@/assets/home/list3-3.png"),
          text: "超越市场的投资回报",
        },
      ],
    },
    {
      imgSrc: require("@/assets/home/list4.png"),
      title: "结构性融资",
      desc: "量身定制完整审慎的融资方案",
      liList: [
        {
          iconSrc: require("@/assets/home/list4-1.png"),
          text: "资产抵押贷款",
        },
        {
          iconSrc: require("@/assets/home/list4-2.png"),
          text: "股权质押融资",
        },
        {
          iconSrc: require("@/assets/home/list4-3.png"),
          text: "多类结构融资项目",
        },
      ],
    },
  ],
  qkMember: "清科成员链接",
};
