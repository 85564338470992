//客户服务
const MarketMall = () =>
  import(/* webpackChunkName:'marketMall' */ "@/views/marketMall");
//港股
const Market = () =>
  import(/* webpackChunkName:'Market' */ "@/views/marketMall/market");
//购买页面
const MarketBuy = () =>
  import(/* webpackChunkName:'MarketBuy' */ "@/views/marketMall/buy");

const route = {
  path: "/mall",
  component: MarketMall,
  name: "MarketMall",
  redirect: "/mall/activity",
  meta: {
    title: "行情商城",
  },
  children: [
    {
      name: "Market",
      path: "/mall/activity",
      component: Market,
      meta: {
        title: "行情商城",
      },
    },
    {
      name: "MarketBuy",
      path: "/mall/buy",
      component: MarketBuy,
      meta: {
        title: "行情商城",
        needLogin: true,
      },
    },
  ],
};

export default route;
