export default {
  helpArr: [
    {
      title: "開戶相關",
      iconUrl: require("@/assets/service/1.png"),
      list: [
        { name: "機構如何開戶?", path: "" },
        { name: "個人如何開戶?", path: "" },
        { name: "如何更改開戶資料?", path: "" },
      ],
    },
    {
      title: "資金相關",
      iconUrl: require("@/assets/service/2.png"),
      list: [
        { name: "入金指引", path: "" },
        { name: "出金指引", path: "" },
        { name: "管理銀行卡", path: "" },
      ],
    },
    {
      title: "股票轉倉",
      iconUrl: require("@/assets/service/3.png"),
      list: [
        { name: "轉入股票", path: "" },
        { name: "轉出股票", path: "" },
      ],
    },
    {
      title: "港股IPO認購",
      iconUrl: require("@/assets/service/4.png"),
      list: [
        { name: "IPO認購說明", path: "" },
        { name: "IPO認購流程", path: "" },
        { name: "IPO中簽查詢", path: "" },
      ],
    },
    {
      title: "港股交易規則",
      iconUrl: require("@/assets/service/5.png"),
      list: [
        { name: "港股交易時間", path: "" },
        { name: "港股交易和收費規則", path: "" },
        { name: "港股漲跌幅說明", path: "" },
        { name: "港股訂單類型", path: "" },
        { name: "港股訂單期限說明", path: "" },
      ],
    },
    {
      title: "港股行情",
      iconUrl: require("@/assets/service/6.png"),
      list: [{ name: "港股行情使用說明", path: "" }],
    },
  ],
};
