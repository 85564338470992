export default {
  componeyName: "清科國際",
  componeyDesc1:
    "清科國際控股（香港）有限公司是清科創業（股票代碼：1945.HK）的全資子公司，是一家立足香港，面向全球的投資銀行，致力於成為連接中國與海外資本市場的橋梁，助力中國企業走向國際資本市場。清科國際依托清科在股權投資行業20年的經驗及資源積累，積極開展跨境及境外企業融資、證券交易、資產管理等業務。",
  componeyDesc2:
    "清科國際擁有一支專業水平出色、執行經驗豐富的團隊。主要團隊負責人均具有10年以上的投行實戰經驗和境內外業務拓展經歷，與境內外監管機構保持著良好的溝通和合作。團隊曾成功幫助眾多企業完成IPO、股票承銷、項目並購等，獲得良好的口碑。",
  componeyDesc3:
    "此外，清科國際依托大數據及互聯網技術，創新地將市場和金融資源整合，為前往香港和海外資本市場的客戶提供一站式綜合服務。清科國際借助自有的行業投融資大數據幫助企業發現價值、確認價值、提升價值、優化資源配置，精準對接資本方。",
  advantageName: "核心優勢",
  advantageDesc:
    "清科國際依托大數據及互聯網技術將市場金融資源整合，為前往香港和海外市場的客戶提供股票交易、投資銀行服務及資產管理等業務。清科國際能夠給客戶提供市場上最全面的投融資大數據並幫助其對接資本方，幫助他們發現企業價值，優化資本、配置資源。",
  advantageListTitle1: "行業數據庫衍生的投行",
  advantageListDesc1:
    "清科深耕中國股權投資行業20年，積累了行業的數據庫。清科國際依托該數據庫和相關經驗，為創業者、擬上市公司及已上市企業提供全方位融資服務，為投資方獲取優質企業資源。",
  advantageListTitle2: "超群的研究實力",
  advantageListDesc2:
    "清科研究中心在國內市場影響力持續提升，清科研究中心的股權行業報告內容覆蓋五大系列，早期投資、創業投資、私募股權投資、企業並購、企業上市。",
  advantageListTitle3: "全面的投資人覆蓋網絡",
  advantageListDesc3:
    "投資人網絡覆蓋超過7萬家，借助20年在創投圈舉辦的各類排名及投資論壇活動等，積累了具市場影響力的投資人網絡資源",
  advantageListTitle4: "便捷的金融大數據搜索系統",
  advantageListDesc4:
    "開發了包含「投資人大數據A-Z一站式索引」，「在線路演，銷售，雲業績發布通道服務」，依托母公司PEDATA MAX強大的投資信息數據系統，滿足用戶「省時高效」、「資訊及時全面」的核心訴求。",
};
