export default {
  router_name: "投资银行",
  topBannerTitle: "投资银行",
  topBannerDesc1:
    "清科资本有限公司（Zero2IPO Capital Limited）是清科国际的全资子公司，以企业融资为核心业务。清科资本已获得香港证监会颁发的第6类受规管业务牌照（CE No:BRH906)，就机构融资提供意见。清科资本汇集了众多来自香港和大陆两地的资深专业人士，为境内外企业提供全面的投资银行服务。清科资本专注于香港IPO的上市保荐、股票承销、股权融资等服务，同时提供企业并购财务顾问、独立财务顾问、合规顾问等业务。",
  productTitle: "业务介绍",
  productList: [
    {
      imgUrl: require("@/assets/business/investBank/product1.png"),
      title: "香港IPO保荐服务和其他企业融资服务",
      desc: "为拟于香港上市的企业提供保荐服务，亦为已在香港上市的公司提供企业并购、资产收购/出售、关连交易、再融资等项目提供财务顾问，独立财务顾问服务。为企业在香港资本市场的发展保驾护航。",
    },
    {
      imgUrl: require("@/assets/business/investBank/product2.png"),
      title: "私募融资服务",
      desc: "为非上市公司海外私募融资提供财务顾问，解決企业上市前的资金需求。清科资本依托集团，与许多投资机构及高净值人士保持着密切的合作关系，助企业成功股权私募融资。",
    },
  ],
  teamProductTitle: "团队介绍",
  teamProductDesc:
    "清科资本拥有一支专业担当、项目执行经验丰富的精英团队。主要团队负责人均具有10年以上的投行实战经验，拥有多年的境内外业务拓展经历，与境内外监管机构保持着良好的沟通和合作。曾成功帮助众多企业完成IPO保荐、股票承销、项目收购等服务，获得良好的口碑。",
};
